module directives {
    export module master {
        interface IProductSizeColourScope extends ng.IScope {
            isSize: boolean;
            ngDisabled: boolean;
            loadProducts(productDescription: string, productCode: string, model: boolean);
            addNewProducts();
            submitProducts(isSize: boolean);
            loadEntities(searchText: string);
            entityChange(model: interfaces.applicationcore.IDropdownModel);
            activeChanged(row: uiGrid.IGridRow);
            gvwProductList: uiGrid.IGridOptions;
            registerProductGridApi(gridApi: uiGrid.IGridApi);
            removeInlineProduct(entity: interfaces.tariff.IProductSizeColourDisplay, productDescription: string, productCode: string, showAll: boolean);
            apiProductList: uiGrid.IGridApi;
            isOpen: boolean;
            IsLoading: boolean;
            showAll: boolean;
            productDescription: string;
            productCode: string;
        }

        export class productSizeColourDirective implements ng.IDirective {
            template = `
                <div>
                    <uib-accordion>
                        <div uib-accordion-group class="panel-default" heading="Advanced Search" is-open="isOpen">
                            <form name="filterForm">
                                <div class="container-fluid">
                                    <!-- Row 1 -->
                                    <div class="row">
                                        <div class="col-md-2">
                                            <opt></opt>
                                            <label>Entity</label>
                                        </div>
                                        <div class="col-md-3">
                                            <gts-dropdown ng-model="entity" form="filterForm" load-data="loadEntities(searchText)" pagination-search="true" refresh-delay="1000" ng-change="entityChange(model)"></gts-dropdown>
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-2">
                                            <opt></opt>
                                            <label>Description</label>
                                        </div>
                                        <div class="col-md-3">
                                            <p class="input-group-sm has-feedback" ng-class="{'has-error': filterForm.description.$invalid}">
                                                <input type="text" name="description" ng-model="productDescription" class="form-control" />
                                            </p>
                                        </div>
                                    </div>
                                    <!-- Row 2 -->
                                    <div class="row">
                                        <div class="col-md-2">
                                            <opt></opt>
                                            <label ng-if="isSize">Size Code</label>
                                            <label ng-if="!isSize">Colour Code</label>
                                        </div>
                                        <div class="col-md-3">
                                            <p class="input-group-sm has-feedback" ng-class="{'has-error': filterForm.description.$invalid}">
                                                <input type="text" name="code" ng-model="productCode" class="form-control" />
                                            </p>
                                        </div>
                                    </div>
                                    <!-- Row 3 -->
                                    <div class="row">
                                        <div class="col-md-2">
                                            <opt></opt>
                                            <label>Show All Active</label>
                                        </div>
                                        <div class="col-md-3">
                                            <p class="input-group-sm has-feedback" ng-class="{'has-error': showAll.$invalid}">
                                                <input type="checkbox" name="showAllName" ng-model="showAll"/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-primary pull-left" ng-click="loadProducts(productDescription, productCode, showAll)">Search</button>
                            </form>
                        </div>
                    </uib-accordion>
                    <button type="button" class="btn btn-primary" ng-click="submitProducts(isSize)">Submit</button>
                    <br />
                    <br />
                    <div>
                        <div id="gridList" ui-grid="gvwProductList" ui-grid-pagination ui-grid-resize-columns ui-grid-selection ui-grid-edit ui-grid-row-edit ui-grid-cellNav class="gridSmallScreen">
                            <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="!gvwProductList.data || IsLoading || gvwProductList.data.length <= 0">
                                <div class="msg" ng-show="gvwProductList.data.length <= 0 && !IsLoading">
                                    <span>No Rows</span>
                                </div>
                                <div class="loadingGrid" ng-show="IsLoading">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            `;
            scope = {
                ngDisabled: "=",
                isSize: "=",
            }
            
            title: string = "";
            productList: Array<interfaces.tariff.IProductSizeColourDisplay> = [];
            gvwProductList: uiGrid.IGridOptions;
            entity: interfaces.applicationcore.IDropdownModel;
            entityId: number = undefined;

            constructor(private generalService: interfaces.applicationcore.IGeneralService,
                public $uibModal: ng.ui.bootstrap.IModalService,
                private productCategoryService: interfaces.master.IProductCategoryService,
                private $state: ng.ui.IStateService,
                private $q: ng.IQService,
                private entityService: interfaces.applicationcore.IEntityService,
                public $timeout: ng.ITimeoutService,
                private productTariffService: interfaces.tariff.IProductTariffService,
            ) {
            }
            
            link = ($scope: IProductSizeColourScope, $element: ng.IAugmentedJQuery, $state) => {
                $scope.isOpen = false;
                $scope.IsLoading = true;
                $scope.showAll = true;
                
                $scope.loadProducts = (productDescription: string, productCode: string, model: boolean) => {
                    $scope.IsLoading = true;
                    $scope.showAll = model;
                    this.loadProducts($scope.isSize, productDescription, productCode, model).then(() => {
                        $scope.IsLoading = false;
                    }, () => {
                        $scope.IsLoading = false;
                    });
                };

                $scope.entityChange = (model: interfaces.applicationcore.IDropdownModel) => {
                    this.entity = model;

                    if (model)
                        this.entityId = model.Id;
                    else
                        this.entityId = undefined;
                }

                this.loadProducts($scope.isSize).then(() => {
                    $scope.IsLoading = false;
                }, () => {
                    $scope.IsLoading = false;
                });

                $scope.addNewProducts = () => {
                    var add = <interfaces.tariff.IProductSizeColourDisplay>{
                        Entity: <interfaces.applicationcore.IDropdownModel>{
                            Id: 0,
                            Code: "",
                            Description: "",
                            Display: "",
                            Selected: true
                        },
                        Code: "",
                        Description: "",
                        IsActive: true,
                    };
                    (<Array<any>>($scope.gvwProductList.data)).splice(0, 0, add);
                };

                $scope.submitProducts = (isSize: boolean) => {
                    //Get dirty products.
                    var productList: Array<interfaces.tariff.IProductSizeColourDisplay> = [];
                    if ($scope.apiProductList && $scope.apiProductList.rowEdit.getDirtyRows().length > 0) {
                        productList = _.map($scope.apiProductList.rowEdit.getDirtyRows(), (o) => {
                            return o.entity;
                        });
                    }

                    //Save Dirty Products
                    if (isSize)
                    {
                        this.productTariffService.SaveProductSizes().save(productList, (data: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(data);

                            this.$timeout(() => {
                                if ($scope.apiProductList)
                                {
                                    $scope.apiProductList.rowEdit.setRowsClean(productList);
                                    $scope.loadProducts($scope.productDescription, $scope.productCode, $scope.showAll); //Updated line
                                }
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    }
                    else
                    {
                        this.productTariffService.SaveProductColours().save(productList, (data: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(data);

                            this.$timeout(() => {
                                if ($scope.apiProductList) {
                                    $scope.apiProductList.rowEdit.setRowsClean(productList);
                                    $scope.loadProducts($scope.productDescription, $scope.productCode, $scope.showAll);
                                }
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    }
                };

                $scope.loadEntities = (searchText: string) => {
                    return this.entityService.getDropdownList(searchText).query(() => {
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                };

                $scope.gvwProductList = {
                    data: <Array<interfaces.tariff.IProductSizeColourDisplay>>[],
                    enableFiltering: false,
                    useExternalFiltering: false,
                    enableCellEdit: false,
                    useExternalSorting: false,
                    multiSelect: false,
                    enableColumnResizing: true,
                    enableFullRowSelection: true,
                    useExternalPagination: false,
                    enableRowHeaderSelection: false,
                    enableHorizontalScrollbar: 2,
                    rowEditWaitInterval: -1,
                    cellEditableCondition: true,
                    paginationPageSizes: [25, 50, 100],
                    paginationPageSize: 25,
                    showGridFooter: true,
                    onRegisterApi: (gridApi) => { $scope.registerProductGridApi(gridApi); },
                    columnDefs: [{
                        name: "REMOVE",
                        displayName: "",
                        field: "ProductRemove",
                        cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.removeInlineProduct(row.entity, productDescription, productCode, showAll)" class="btn btn-warning btn-sm">
                                    Delete
                                </button>
                            </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEditOnFocus: false,
                        width: 55,
                        headerCellTemplate: `
                            <div class="GridButton">
                                <button type="button"  class="btn btn-default btn-sm" ng-click="grid.appScope.addNewProducts()">
                                    <span class="fa fa-plus"></span>
                                </button>
                            </div>`
                    }, {
                        name: "ENTITY",
                        displayName: "* Entity",
                        field: "Entity",
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        width: 200,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.loadEntities(searchText)" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                            </form>`,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: true,
                    }, {
                        name: "CODE",
                        displayName: "* Code",
                        field: "Code",
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        width: 150,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: true,
                        type: "string",
                        cellClass: 'text-right',
                        editableCellTemplate: `
                            <form name = "inputForm">
                                <p class="input-group-sm">
                                    <input type="text" name="Code-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor step = "0.01" />
                                </p>
                            </form>`,
                    }, {
                        name: "DESCRIPTION",
                        displayName: "* Description",
                        field: "Description",
                        width: 150,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: true,
                        type: "string",
                        cellClass: 'text-right',
                        editableCellTemplate: `
                        <form name = "inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="Description-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor step = "0.01" />
                            </p>
                        </form>`,
                    }, {
                        name: "ACTIVE",
                        displayName: "* Active",
                        field: "IsActive",
                        width: 100,
                            cellTemplate: `<input type="checkbox" ng-change="grid.appScope.activeChanged(row)" ng-model="row.entity.IsActive">`,
                        editableCellTemplate: ` 
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="checkbox" ng-model="row.entity.IsActive">
                                </p>
                            </form>`,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: true,
                    }, {
                        name: "INFO",
                        displayName: "",
                        field: "",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <gts-stamp-info createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate"
                                updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate">
                            </gts-stamp-info>
                        </div>`,
                            width: 38,
                        enableSorting: false,
                    },]
                };

                $scope.activeChanged = (row: uiGrid.IGridRow) => {
                    $scope.apiProductList.rowEdit.setRowsDirty([row.entity]);
                }

                $scope.registerProductGridApi = (gridApi: uiGrid.IGridApi) => {
                    $scope.apiProductList = gridApi;

                    $scope.apiProductList.cellNav.on.navigate($scope, (gridSelection) => {
                        $scope.apiProductList.selection.selectRow(gridSelection.row.entity);
                    });

                    this.$timeout(() => {
                        $scope.gvwProductList.data = this.productList;
                    });
                };

                $scope.removeInlineProduct = (entity: interfaces.tariff.IProductSizeColourDisplay, productDescription: string, productCode: string, showAll: boolean) => {
                    if (entity.Id > 0) {
                        $scope.IsLoading = true;
                        if ($scope.isSize)
                        {
                            return this.productTariffService.removeProductSize().delete({
                                prodId: entity.Id,
                            }, (result) => {
                                this.$timeout(() => {
                                    $scope.IsLoading = true;
                                    this.loadProducts($scope.isSize, productDescription, productCode, $scope.showAll).then(() => {
                                        $scope.IsLoading = false;
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                        $scope.IsLoading = false;
                                    });
                                });
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                });
                        } else
                        {
                            return this.productTariffService.removeProductColor().delete({
                                prodId: entity.Id,
                            }, (result) => {
                                this.$timeout(() => {
                                    $scope.IsLoading = true;
                                    this.loadProducts($scope.isSize, productDescription, productCode, $scope.showAll).then(() => {
                                        $scope.IsLoading = false;
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                        $scope.IsLoading = false;
                                    });
                                });
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            });
                        }
                    }
                    else {
                        _.remove(this.gvwProductList.data, (o) => {
                            return o === entity;
                        });
                    }
                }

                this.gvwProductList = $scope.gvwProductList;
            }

            loadProducts(isSize: boolean, description: string = "", code: string = "", model: boolean = true)
            {
                if (isSize)
                {
                    return this.productTariffService.GetProductSize().query({
                        entityId: this.entityId,
                        description: description,
                        sizeCode: code,
                        showAll: model,
                    }, (result: Array<interfaces.tariff.IProductSizeColourDisplay>) => {
                        this.productList = result;
                        this.gvwProductList.data = result;
                    }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                }
                else
                {
                    return this.productTariffService.GetProductColour().query({
                        entityId: this.entityId,
                        description: description,
                        colourCode: code,
                        showAll: model,
                    }, (result: Array<interfaces.tariff.IProductSizeColourDisplay>) => {
                        this.productList = result;
                        this.gvwProductList.data = result;
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                }
                
            }
            
            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $uibModal, productCategoryService, $state, $q, entityService, $timeout, productTariffService) => new productSizeColourDirective(generalService, $uibModal, productCategoryService, $state, $q, entityService, $timeout, productTariffService);
                directive.$inject = ["generalService", "$uibModal", "productCategoryService", "$state", "$q", "entityService", "$timeout", "productTariffService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsProductSizeColour", productSizeColourDirective.factory());
    }
}